module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.12.2_encoding@0.1.13_gatsby@5.12.11_@types+webpack@4.41.38_1ec62f42ae9ef18c7eefd311a3007e23/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-axe@0.5.0_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_39fd208811abadd2065136f93130c5c8/node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10._eb5477b0dd2aa93440eb0bb2a50e2044/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-reveal@0.0.7_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10_5aa9b09acd512030eadd6c418a1aebc6/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-reveal@0.0.7_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10_5aa9b09acd512030eadd6c418a1aebc6/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-launchdarkly@1.0.0_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10._ee8d5ba1d0f81b7b2610e683aabab0ca/node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"66ccb5f628f86a0fa634d4bf","options":{"bootstrap":"localStorage"},"user":{"key":"anonymous-user","anonymous":true}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.12.11_@types+webpack@4.41.38_babel-esli_7860beebb26f1df2566a254c65026009/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KJSM5X","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@8.54.0__encoding@0.1.1_9fa211ac8b0745bd92e0a4d5d9c9dbfd/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
